import React, { useState, useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import useModelLoader from "../../../hooks/useModelLoader";
import MakeEstablishmentsListPDF from "./MakeEstablishmentsListPDF";
import fonts from "../../../utils/fonts";
import { parseHtmlToTextArray } from "../../../utils/utils";


const BackgroundImage = ({ src, zoom = 1 }) => {
  const objectFitStyle = zoom <= 1 ? "contain" : "cover";
  const containerHeight = "100%";
  const imageVerticalOffset = zoom <= 1 ? "33.33%" : "0%"; // Offset for when zoom <= 1

  return (
    <Image
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: containerHeight,
        objectFit: objectFitStyle,
        transform: `scale(${zoom}) translateY(${imageVerticalOffset})`,
        transformOrigin: zoom <= 1 ? "center top" : "center center",
        zIndex: 2,
      }}
      src={src}
    />
  );
};

const framedMessage = {
  FR: 'Oeuvre Encadrée',
  EN: 'Framed Artwork',
  JA: '額入りアートワーク'
};


const availableMessage = {
  1: {
    FR: 'Oeuvre Disponible',
    EN: 'Artwork Available', // Corrected from 'Artwork not available'
    JA: '作品利用可能' // Added Japanese translation for 'Artwork Available'
  },
  2: { // Changed key from 1 to 2 for the second message
    FR: 'Oeuvre Non Disponible',
    EN: 'Artwork Not Available', // Capitalized 'Not'
    JA: '作品利用不可' // Added Japanese translation for 'Artwork Not Available'
  }
};



const isProducedMessage = {
  FR: 'Oeuvre non éditée / réalisée',
  EN: 'Artwork to be produced',
  JA: '未編集/実現済みの作品'
};


const isUniqueArtworkMessage = {
  FR: 'Oeuvre unique',
  EN: 'Unique Artwork',
  JA: 'ユニークな作品'
};


const isEditionArtworkMessage = ({ language, number, total, isMultiple, whenToShow }) => {
  if(!isMultiple && whenToShow=='unique'){
    return isUniqueArtworkMessage[language];
  }
  else if(isMultiple){
    if (language === 'FR') {
      return `Edition ${number} de ${total}`;
    } else if (language === 'EN') {
      return `Edition ${number} of ${total}`;
    } else if (language === 'JA') {
      return `${number}版中の${total}`;
    } 
  }
  if(!isMultiple && whenToShow=='unique'){
    return isUniqueArtworkMessage[language];
  }
  
};




const styles = StyleSheet.create({
  frontPageCard: (backcolor="transparent", alignment="right") => ({
    backgroundColor: backcolor,
    position: "absolute", 
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "30px",
    paddingBottom: "30px",
    textAlign: alignment === "left"? "right" : "left",
    marginLeft: alignment === "right"? "38%" : "0px",
    marginTop: alignment === "bottom" ? "600px" : "500px",
    width:  alignment === "bottom"? "100%" : "62%",
    height: alignment === "bottom"? "30%" : "auto",
    zIndex: 1,
  }),
  frontPage: {
    flexDirection: "column",
    backgroundColor: "white",
    height: "100%", // Ensure the page takes full height
  },
  frontPageTitle: {
    fontFamily: "Museo500",
    letterSpacing: 3,
    fontSize: 32,
  },
  frontPageSubtitle: {
    paddingTop:2,
    fontFamily: "MuseoSlab300",
    letterSpacing: 3,
    fontSize: 18,
  },
  frontPageInfoLine: {
    paddingTop:5,
    paddingRight: 3,
    fontFamily: "Museo100",
    fontSize: 12,
  },
  logoFrontPage: {
    fontFamily: "Museo100",
    letterSpacing: 5,
    fontSize: 12,
  },
  logoFrontPageCity: {
    fontFamily: "Museo300",
    paddingLeft: 3,
    letterSpacing: 1,
    fontSize: 12,
  },
  translatableText: (language) => ({
    fontFamily: language === "JA" ? "NotoRegular" : "Museo100",
  }),
  logoDiv: (alignment, city="NoCity") => ({
    paddingTop: 8,
    paddingRight: 3,
    flexDirection: "row",
    marginRight: city === "NoCity" ? -5 : 0,
    justifyContent: alignment === "left" ? "flex-end" : "flex-start",
  }),
  artistName: {
    paddingTop:2,
    fontFamily: "MuseoSlab500",
    letterSpacing: 1,
    fontSize: 10,
    textTransform: 'uppercase'
  },
  artworkTitle: {
    paddingTop:2,
    fontFamily: "Museo300i",
    fontSize: 9,
  },
  artworkDate: {
    paddingTop:2,
    fontFamily: "Museo300",
    fontSize: 9,
  },
  page: {
    fontFamily: "Museo100",
    fontSize: 8,
    flexDirection: "column",
    backgroundColor: "white",
    padding: 10,
    height: "100%", // Ensure the page takes full height
    justifyContent: "flex-start",
  },
  gridContainer: {
    marginTop: 10,
    paddingLeft: 10,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  barcodeContainer: (entriesPerPage) => ({
    height: entriesPerPage === 10 ? "20%" : "10%",
    width: "100%", // Barcode container takes full width of the section
    alignItems: "center", // Center barcode horizontally
    marginBottom: 5, // Add some margin at the bottom of the barcode
  }),
  textContainer: (entriesPerPage) => ({
    fontSize: 7,
    padding: 2,
    width: entriesPerPage === 10 || entriesPerPage === 8 || entriesPerPage === 5 ? "60%" : "100%",
    height: entriesPerPage === 10 || entriesPerPage === 8 || entriesPerPage === 5 ? "100%" : "50%",
    position: "relative", // Ensure the container is relative
    justifyContent: "center",
    alignItems: "left",
    display: "flex", // Use flex to center the image
  }),
  imageContainer: (entriesPerPage) => ({
    width: entriesPerPage === 10 || entriesPerPage === 5 ? "40%" : entriesPerPage === 8 ? "40%" :"100%",
    height: entriesPerPage === 10 || entriesPerPage === 5 ? "100%" : entriesPerPage === 8 ? "100%" :"50%",
    position: "relative", // Ensure the container is relative
    justifyContent: "center",
    alignItems: "center",
    paddingRight:7,
    display: "flex",
  }),
  section: (entriesPerPage) => ({
    padding: 2,
    width: entriesPerPage === 1 ? "100%" : entriesPerPage === 5 ? "100%" : "275px",
    height:
      entriesPerPage === 1 ? "720px" : entriesPerPage === 4 ? "345px" : entriesPerPage === 8 ? "180px" :"147px",
    flexGrow: 0,
    flexShrink: 1,
    marginBottom: "auto",
    position: "relative",
    display: "flex",
    flexDirection: entriesPerPage === 10 ? "column" : "column",
    alignItems: "flex-start",
  }),
  image: (entriesPerPage) => ({
    maxHeight:
      entriesPerPage === 1 ? "800px" : entriesPerPage === 4 ? "200px" : "70px",
    maxWidth:
      entriesPerPage === 1 ? "590px" : entriesPerPage === 4 ? "300px" : "120px",
    alignSelf: "center",
  }),
  contentContainer: (entriesPerPage) => ({
    flexDirection: entriesPerPage === 10 || entriesPerPage === 5 || entriesPerPage === 8 ? "row" : "column",
    width: "100%", // Content container takes full width
  }),
  footerContainer: {
    position: "absolute", // Position the footer absolutely
    bottom: 0, // Stick to the bottom
    left: 0,
    right: 0,
    width: "100%",
    paddingLeft: "20px",
    paddingRight: "20px",
    fontFamily: "Museo100",
    flexDirection: "row",
    justifyContent: "space-between", // This will space out your items
    alignItems: "center",
    marginTop: 10,
    marginBottom: 30,
  },
  footerItem: {
    fontFamily: "Museo100",
    fontSize: 9,
    width: "33%",
    textAlign: "center", // Center text in each section
  },
  footerLeft: {
    fontFamily: "Museo100",
    textAlign: "left", // Align text to the left for the left section
  },
  footerRight: {
    fontFamily: "Museo100",
    textAlign: "right", // Align text to the right for the right section
  },
  header: {
    letterSpacing: "3px",
    fontFamily: "Museo100",
    fontSize: 14,
    textAlign: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  footer: {
    fontFamily: "Museo100",
    fontSize: 8,
    textAlign: "center",
    margin: 10,
  },
  cbLogoSection: {
    width: "100%",
    height: "15px",
    fontFamily: "Museo100",
    letterSpacing: "3px",
    fontSize: 9,
    marginTop: 5,
    textAlign: "center",
  },
  cbLogoCartel: {
    width: "100%",
    height: "12px",
    fontFamily: "Museo100",
    letterSpacing: "2px",
    fontSize: 9,
    marginTop: 2,
    marginBottom: 2,
    textAlign: "center",
  },
  generated: {
    width: "100%",
    height: "10px",
    fontFamily: "Museo100",
    fontSize: 6,
    textAlign: "center",
  },
  barcodeValue: (entriesPerPage) => ({
    fontSize: entriesPerPage === 1 ? 14 : 6,
    width: "100%",
    fontFamily: "Museo300",
    textAlign: "center",
  }),
});

const Header = () => <Text style={styles.header}>Ceysson & Bénétière</Text>;

const Footer = ({ pageIndex, totalPages }) => (
  <View style={styles.footerContainer}>
    <Text style={[styles.footerItem, styles.footerLeft]}>loic@ceysson.com</Text>
    <Text style={styles.footerItem}>www.ceyssonbenetiere.com</Text>
    <Text style={[styles.footerItem, styles.footerRight]}>
      {pageIndex + 1} of {totalPages}
    </Text>
  </View>
);

const ArtworksCheckListPDF = ({
  section,
  checkedFields,
  checkedCustomFields,
  radioCustomValues,
  selectedRowIds,
  entriesPerPage,
  data,
  ...props
}) => {
  const { modelData } = useModelLoader(section);


  const [changeRates, setChangeRates] = useState(null);

  useEffect(() => {
    fonts();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://changerates.s3.eu-west-3.amazonaws.com/changerates.json"
        );
        const jsonData = await response.json();
        setChangeRates(jsonData);
        console.log("changeRates", jsonData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedRowIds]);





  const makeChangeRate = (price, currency) => {
    if (!changeRates || changeRates.length === 0 || !price || !currency) {
      return { error: "Price conversion data not available" };
    }

    // Find the exchange rate data that is up to date within the last 5 days
    const latestRate = changeRates.find((rate) => {
      const currentDate = new Date();
      const rateDate = new Date(rate.date_of_data);
      const timeDifference = currentDate - rateDate;
      const daysDifference = timeDifference / (1000 * 3600 * 24);

      return daysDifference <= 5;
    });

    if (!latestRate) {
      return { error: "Price conversion data not up to date, do not use" };
    }

    const priceNum = parseFloat(price);
    const conversionRates = {
      USD: {
        EUR: latestRate.USD_TO_EUR,
        CHF: latestRate.USD_TO_CHF,
        JPY: latestRate.USD_TO_JPY,
      },
      EUR: {
        USD: 1 / latestRate.USD_TO_EUR,
        CHF: latestRate.EUR_TO_CHF,
        JPY: latestRate.EUR_TO_JPY,
      },
      CHF: {
        USD: 1 / latestRate.USD_TO_CHF,
        EUR: 1 / latestRate.EUR_TO_CHF,
        JPY: latestRate.CHF_TO_JPY,
      },
      JPY: {
        USD: 1 / latestRate.USD_TO_JPY,
        EUR: 1 / latestRate.EUR_TO_JPY,
        CHF: 1 / latestRate.CHF_TO_JPY,
      },
    };

    if (conversionRates[currency]) {
      const convertedRates = {};
      for (const targetCurrency in conversionRates[currency]) {
        convertedRates[targetCurrency] = (
          priceNum * parseFloat(conversionRates[currency][targetCurrency])
        ).toFixed(2);
      }
      //convertedRates.DateOfGeneration = latestRate.date_of_generation;
      return convertedRates;
    }

    return { error: "Unsupported currency" };
  };

  const chunkData = (data, size) => {
    const result = [];
    for (let i = 0; i < data.length; i += size) {
      result.push(data.slice(i, i + size));
    }
    return result;
  };

  const getTotalPages = (data, entriesPerPage) => {
    return Math.ceil(data.length / entriesPerPage);
  };

  const totalPages = getTotalPages(data, entriesPerPage);

  const groupedData = chunkData(data, entriesPerPage);

  console.log('groupedData',groupedData);

  return (
    <Document>
           {checkedCustomFields["showFrontPage"] && (
        <Page key="FrontPage" style={styles.frontPage}>
          <View style={{ position: 'relative', width: '100%', height: '100%' }}>
            {checkedCustomFields["artworkCover"] && (
              <BackgroundImage
                zoom={checkedCustomFields["zoom"]}
                src={`${process.env.REACT_APP_S3_BASE_IMG}${section}/${checkedCustomFields["artworkCover"]}-2000-1200.jpg`}
              />
            )}
            <View style={styles.frontPageCard(radioCustomValues.CardFrontBackGroundWhite, radioCustomValues.alignment )}>
              <Text style={styles.frontPageTitle}>
                {checkedCustomFields["title"]}
              </Text>
              <Text style={styles.frontPageSubtitle}>
                {checkedCustomFields["subtitle"]}
              </Text>
              <Text style={styles.frontPageInfoLine}>
                {checkedCustomFields["infoline"]}
              </Text>
              {radioCustomValues.logos!="noLogo" && (
               <View style={styles.logoDiv(radioCustomValues.alignment, radioCustomValues.logos)}>
               <Text style={styles.logoFrontPage}>Ceysson & Bénétière</Text>
               {radioCustomValues.logos!="NoCity" && (
               <Text style={styles.logoFrontPageCity}>{radioCustomValues.logos}</Text>)}
             </View>)}
            </View>
          </View>
        </Page>
      )}
      {groupedData.map((group, pageIndex) => (
        <Page key={pageIndex} style={styles.page}>
          <Header />
          <View style={styles.gridContainer}>
            {group.map((item, itemIndex) => (

              <View key={itemIndex} style={styles.section(entriesPerPage)}>

              

                {checkedCustomFields.showCBlogoEverywhere && entriesPerPage>1 && (
                <View style={{width: "100%", height: "20px", backgroundColor: "#fff", marginBottom: "2px"}}>
                  <Text style={styles.cbLogoCartel}>Ceysson & Bénétière</Text>
                  </View>
                  )}

<View style={styles.contentContainer(entriesPerPage)}>



                {checkedCustomFields.showImage && ( <View style={styles.imageContainer(entriesPerPage)}>
             
                  <Image
                      style={styles.image(entriesPerPage)}
                      src={`${process.env.REACT_APP_S3_BASE_IMG}${section}/${item.id}/main_image/${item.main_image}-${radioCustomValues.imageQuality}.jpg`}
                    />
                  </View>)}


                  <View style={styles.textContainer(entriesPerPage)}>
                    {checkedCustomFields.showArtistName && (
                      <Text style={styles.artistName}>{item.artists_id}</Text>
                    )}


                    {checkedCustomFields.showArtistDate &&
                      parseHtmlToTextArray(item.artist_birth_info).map((line, i) => (
                        <Text key={i}>{line}</Text>
                      ))}

<Text></Text>
                <View style={{flexDirection:"row", flexWrap: "wrap" }}>
               <Text style={styles.artworkTitle}>{item.name}, </Text>
               <Text style={styles.artworkDate}>{item.artworks_creation_date_start}</Text>
               {item.artworks_creation_date_end!=null && (
              <Text style={styles.artworkDate}> - {item.artworks_creation_date_end}</Text>
                    )}
             </View>

                    <Text>{item["subtitle"]}</Text>
                   {checkedCustomFields.showTechnique && <>
                    <Text>{item["added_medium_en"]}</Text>
                    {radioCustomValues.language === "JA" && (
                      <Text style={styles.translatableText(radioCustomValues.language)}>{item["added_medium_ja"]}</Text>
                    )}
                    {radioCustomValues.language === "FR" && (
                      <Text>{item["added_medium_fr"]}</Text>
                    )}
                   </>

                    }
                    
                    <Text>
                      {item["height_cm"]} x {item["width_cm"]}
                      {item["depth_cm"] > 0 && `x ` + item["depth_cm"]} cm
                    </Text>
                    <Text>
                      {item["height_in"]} x {item["width_in"]}
                      {item["depth_in"] > 0 && `x ` + item["depth_in"]} in
                    </Text>

                    {checkedCustomFields.showPrice && !(checkedCustomFields.dontShowPriceIfNotAvailable && item?.artworks_availability === 2 )&& (
                      <Text>
                        {item["public_price"]} {item["public_price_currency"]}
                      </Text>
                    )}



                    {checkedCustomFields.showAvailability && item?.artworks_availability && availableMessage[item.artworks_availability] && (
                      <Text style={styles.translatableText(radioCustomValues.language)}>
                        {availableMessage[item.artworks_availability][radioCustomValues.language]}
                      </Text>
                    )}
                    {checkedCustomFields.showChangeRate && (
                      <Text>
                        {Object.entries(
                          makeChangeRate(
                            item["public_price"],
                            item["public_price_currency"]
                          )
                        )
                          .map(([currency, value]) => `${value} ${currency}`)
                          .join(", ")}
                      </Text>
                    )}


              {item.is_framed === 1 && (
                 <Text style={styles.translatableText(radioCustomValues.language)}>{framedMessage[radioCustomValues.language]}</Text>
                    )}


                {checkedCustomFields.showProductionStatus && item.is_produced === 0 && (
                       <Text style={styles.translatableText(radioCustomValues.language)}>{isProducedMessage[radioCustomValues.language]}</Text>
                       )}



                  {checkedCustomFields.showEditionStatus && (
                       <Text style={styles.translatableText(radioCustomValues.language)}>{isEditionArtworkMessage({ language: radioCustomValues.language, number: item.multiple_edition_number, total: item.total_edition_number, isMultiple: item.is_multiple, whenToShow: radioCustomValues.multipleShow})}</Text>
                   )}

                  </View>





                </View>
                {checkedCustomFields["cblogo"] && entriesPerPage > 1 && (
                  <View style={styles.cbLogoSection}>
                    <Text>Ceysson & Bénétière</Text>
                  </View>
                )}
              </View>
            ))}
          </View>
          <Footer pageIndex={pageIndex} totalPages={totalPages} />
        </Page>
      ))}
      {checkedCustomFields["showBackCover"] && (
        <Page
          key="BackPage"
          style={{
            backgroundColor: checkedCustomFields["backCoverColor"] || "white",
          }}
        >
          <MakeEstablishmentsListPDF
            textColor={checkedCustomFields["TextInWhite"] ? "white" : "black"}
          />
        </Page>
      )}
    </Document>
  );
};

export default ArtworksCheckListPDF;
