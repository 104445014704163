import React, { useState, useEffect, useMemo, useRef } from "react";
import { PDFDownloadLink, BlobProvider } from "@react-pdf/renderer";
import ArtworksCheckListsPDF from "../DocumentRender/ArtworksCheckListsPDF";
import { ChromePicker, SwatchesPicker } from "react-color";
import {
  FormControl,
  Checkbox,
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Button,
  InputLabel,
  Slider,
} from "@mui/material";
import axios from "axios";
import useModelLoader from "../../../hooks/useModelLoader";
import dayjs from "dayjs";
import { useSelectOptions } from "../../../hooks/useSelectOptions";

function MakeSpecificArtworksPdfFormModal({
  itemData,
  query,
  section,
  selectedRowIds,
  fromTable,
  fromId
}) {
  const [entriesPerPage, setEntriesPerPage] = useState("1");
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const [data, setData] = useState(null);
  const [finalData, setFinalData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [checkboxValues, setCheckboxValues] = useState({});
  const [checkboxCustomValues, setCheckboxCustomValues] = useState({
    colorBackCover: "#ffffff",
  });
  const [techniquesData, setTechniquesData] = useState({});
  const [artistsData, setArtistsData] = useState([]);

  const [radioCustomValues, setRadioCustomValues] = useState({
    logos: "NoCity",
    alignment: "left",
    language: "EN", // Set the default value here
    zoom: 1,
    multipleShow: 'multiple',
    imageQuality: '900-600'
  });
  const [logos, setLogos] = useState([]);
  const [sortOrders, setSortOrders] = useState([{ field: "", order: "" }]);

  // Example fields
  const sortOrdersOptions = ["ASC", "DESC"];

  const handleAddSortOrder = () => {
    setSortOrders([...sortOrders, { field: "id", order: "ASC" }]);
  };

  const handleChangeSortField = (index, value) => {
    const updatedSortOrders = [...sortOrders];
    updatedSortOrders[index].field = value;
    setSortOrders(updatedSortOrders);
  };

  const handleChangeSortOrder = (index, value) => {
    const updatedSortOrders = [...sortOrders];
    updatedSortOrders[index].order = value;
    setSortOrders(updatedSortOrders);
    console.log(sortOrders);
  };

  const handleDeleteSortOrder = (index) => {
    const updatedSortOrders = sortOrders.filter((_, i) => i !== index);
    setSortOrders(updatedSortOrders);
  };

  useEffect(() => {
    fetch(
         `${process.env.REACT_APP_CBP_PUBLIC_DATA_URL}cbp-public-json/establishments/establishments_table.json`

    )
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data.filter((item) => item.open_to_public === 1);
        setLogos(filteredData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleRadioChange = (event) => {
    setEntriesPerPage(event.target.value);
  };

  const { modelData, isLoading: isModelDataLoading } = useModelLoader(section);


  useEffect(() => {
    if (modelData?.fields) {
      const initialCheckboxValues = modelData.fields.reduce((acc, field) => {
        if (field.pdfOptions) {
          acc[field.key] = field.pdfOptions === "default";
        }
        return acc;
      }, {});
      setCheckboxValues(initialCheckboxValues);
    }
  }, [modelData?.fields]);

  const findSortableFieldsForPdf = (modelData) => {
    if (!modelData || !modelData.fields) return [];

    // Filter and map the fields to get key and title of sortable fields
    const sortableFields = modelData.fields
      .filter((field) => field.sortableFieldinPdf === true)
      .map((field) => ({ key: field.key, title: field.title }));

    return sortableFields;
  };

  const sortableFields = findSortableFieldsForPdf(modelData);

  const handleCustomOptionChange = (event) => {
    setCheckboxCustomValues({
      ...checkboxCustomValues,
      [event.target.name]: event.target.checked,
    });
  };

  const handleFieldChange = (event) => {
    setCheckboxCustomValues({
      ...checkboxCustomValues,
      [event.target.name]: event.target.value,
    });
  };

  const handleColorChange = (color, event) => {
    setCheckboxCustomValues({
      ...checkboxCustomValues,
      backCoverColor: color.hex, // Use color.hex to get the color string
    });
  };

  const handleCustomRadioChange = (event) => {
    console.log("radio", event.target.name, event.target.value)
    setRadioCustomValues({
      ...radioCustomValues,
      [event.target.name]: event.target.value,
    });
  };

  // Using useRef to persist the axios instance
  const api = useRef(
    axios.create({
      baseURL: `${process.env.REACT_APP_APIGETAWAY_URL}`,
      headers: {
        "Content-Type": "application/json",
      },
    })
  ).current;

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("CognitoJWTToken");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const timestamp = new Date().getTime();




  useEffect(() => {
    if (selectedRowIds != null && selectedRowIds.length > 0) {
      const fetchItems = async () => {
        try {
          setIsLoading(true);
          const idsString = selectedRowIds.join(",");
  
          // Start with the basic query parameters
          let queryParams = {
            table: section,
            ids: idsString,
            fields: " * "
          };
  
          // Add from_id, from_model, and junction_table if fromTable and fromId are available
          if (fromTable && fromId) {
            queryParams = {
              ...queryParams,
              from_id: fromId,
              from_model: fromTable,
              junction_table: `${fromTable}_${section}`,
              position_with_ids: 'position'
            };
          }
  
          // Construct the query string
          const queryString = new URLSearchParams(queryParams).toString();
          const response = await api.get(`?${queryString}`);
          setData(response.data);
          console.log("data", response.data);
        } catch (err) {
          console.error("Error:", err);
          setError(err);
        } finally {
          setIsLoading(false);
        }
      };
  
      fetchItems();
    }
  }, [section, selectedRowIds, fromTable, fromId]); 
  


  //pour les techniques

  const fetchTechniques = async (techniquesIds) => {
    const idsString = techniquesIds.join(",");
    let queryParams = {
      table: "techniques",
      ids: idsString,
      fields: "id,medium_en,medium_fr,medium_ja "
    };
    const queryString = new URLSearchParams(queryParams).toString();
    try {
      const response = await api.get(`?${queryString}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching techniques:", error);
      return [];
    }
  };

  useEffect(() => {
    if (data) {
      const techniquesIds = [...new Set(data.map(item => item.techniques_id))];
  
      if (techniquesIds.length > 0) {
        fetchTechniques(techniquesIds).then((techniques) => {
          setTechniquesData(techniques);
        });
      }
    }
  }, [data]);



  /*
  useEffect(() => {
    if (data && techniquesData.length > 0) {
      const processedData = data.map((item) => {
        const newItem = { ...item };
        console.log("techniquesData", techniquesData)
        const matchingTechnique = techniquesData.find(
          (tech) => tech.id === item.techniques_id
        );
  
        if (matchingTechnique) {
          newItem.added_medium_fr = matchingTechnique.medium_fr;
          newItem.added_medium_ja = matchingTechnique.medium_ja;
          newItem.added_medium_en = matchingTechnique.medium_en;
        } else {
          newItem.added_medium_fr = "Unknown Medium";
          newItem.added_medium_ja = "Unknown Medium";
          newItem.added_medium_en = "Unknown Medium";
        }
  console.log(newItem.medium_en)
        return newItem;
      });
  
      setFinalData(processedData);
    }
  }, [data, techniquesData]);
  
  /**/
  




  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_GLOBAL_MAPPING_URL}dynamic_json/artists.json?SDER`
    )
      .then((response) => response.json())
      .then((data) => setArtistsData(data))
      .catch((error) => console.error("Error fetching artists data:", error));
  }, []);

  /*
  useEffect(() => {
    if (modelData?.fields && data && artistsData) {
      const processedData = data.map((item) => {
        // Initialize a new object with existing data
        const newItem = { ...item };

        // Find the matching artist based on artists_id
        const matchingArtist = artistsData.find(
          (artist) => artist.id === item.artists_id
        );

        if (matchingArtist) {
          // Replace artists_id with the artist's name
          newItem.artists_id = matchingArtist.name;

          // Add a new key for artist's last name
          newItem.artist_last_name = matchingArtist.last_name;
          newItem.artist_birth_info = matchingArtist.text_for_labels;
        } else {
          // If no matching artist is found, set default values
          newItem.artists_id = "Unknown Artist";
          newItem.artist_last_name = "Unknown Last Name";
        }
        

        return newItem;
      });

      setFinalData(processedData);
      console.log('processedData', processedData);
    }
  }, [data, modelData?.fields, artistsData]);*/

  useEffect(() => {
    // Fetch techniques data
    const fetchTechniques = async (techniquesIds) => {
      const idsString = techniquesIds.join(",");
      let queryParams = {
        table: "techniques",
        ids: idsString,
        fields: "id,name,medium_fr,medium_ja "
      };
      const queryString = new URLSearchParams(queryParams).toString();
      try {
        const response = await api.get(`?${queryString}`);
        return response.data;
      } catch (error) {
        console.error("Error fetching techniques:", error);
        return [];
      }
    };
  
    // Combine artist and techniques data processing
    const processData = async () => {
      if (modelData?.fields && data && artistsData) {
        let processedData = data.map((item) => {
          // Find the matching artist based on artists_id
          const matchingArtist = artistsData.find(
            (artist) => artist.id === item.artists_id
          );
  
          // Artist processing
          const newItem = { ...item };
          if (matchingArtist) {
            newItem.artists_id = matchingArtist.name;
            newItem.artist_last_name = matchingArtist.last_name;
            newItem.artist_birth_info = matchingArtist.text_for_labels;
          } else {
            newItem.artists_id = "Unknown Artist";
            newItem.artist_last_name = "Unknown Last Name";
          }
  
          // Techniques processing (if you want to keep it)
          const techniquesIds = [...new Set(data.map(item => item.techniques_id))];
          if (techniquesIds.length > 0) {
            fetchTechniques(techniquesIds).then((techniquesData) => {
              const matchingTechnique = techniquesData.find(
                (tech) => tech.id === item.techniques_id
              );
  
              if (matchingTechnique) {
                newItem.added_medium_fr = matchingTechnique.medium_fr;
                newItem.added_medium_ja = matchingTechnique.medium_ja;
                newItem.added_medium_en = matchingTechnique.name;
              } 
            });
          }
  
          return newItem;
        });
  
        setFinalData(processedData);
      }
    };
  
    processData();
  }, [data, modelData?.fields, artistsData, checkboxCustomValues["showAvailability"]]);


  const sortData = (data, sortOrders) => {
    return [...data].sort((a, b) => {
      for (let i = 0; i < sortOrders.length; i++) {
        const { field, order } = sortOrders[i];

        let valA = a[field];
        let valB = b[field];

        // Check if the values are numeric and convert them to numbers for comparison
        const isNumeric = !isNaN(parseFloat(valA)) && !isNaN(parseFloat(valB));
        if (isNumeric) {
          valA = parseFloat(valA);
          valB = parseFloat(valB);
        }

        if (valA < valB) return order === "ASC" ? -1 : 1;
        if (valA > valB) return order === "ASC" ? 1 : -1;

        // Continue to the next sort order if the current one results in a tie
      }
      return 0; // if all sorting fields are equal or it's a tie in all fields
    });
  };

  useEffect(() => {
    if (finalData) {
      const sortedData = sortData(finalData, sortOrders);
      setFinalData(sortedData);
    }
  }, [sortOrders]);

  const documentMemo = useMemo(() => {
    if (!finalData) {
      return null;
    }

    let ComponentToCall;

    ComponentToCall = ArtworksCheckListsPDF;
    return (
      <ComponentToCall
        checkedFields={checkboxValues}
        checkedCustomFields={checkboxCustomValues}
        radioCustomValues={radioCustomValues}
        entriesPerPage={parseInt(entriesPerPage)}
        section={section}
        data={finalData}
      />
    );
  }, [
    entriesPerPage,
    section,
    finalData,
    checkboxValues,
    checkboxCustomValues,
    radioCustomValues,
    sortOrders,
  ]);


  const selectedRowCount = selectedRowIds?.length || 0;

  useEffect(() => {
    // Reset the blob URL to force regeneration
    setPdfBlobUrl(null);
  }, [documentMemo, checkboxValues]);

  if (isLoading || isModelDataLoading) {
    return <div>Loading...</div>; // Handle loading state for both API data and model data
  }

  return (
    <div style={{ display: "flex", width: "100%" }}>
      {/* Form Section */}
      <div
        style={{
          width: "50%",
          paddingRight: "30px",
          height: "100%",
          overflow: "auto",
        }}
      >
        {selectedRowCount} records in {section} selected
        <br/>
        <form>
          {/*fromTable && fromId ? (<div>Search with order</div>) : (<div>No Search with order</div>)*/} 
          {/*fromTable} - {fromId} - {section}*/}
          <Button style={{ marginRight: '10px', marginTop: '10px', float: 'right'}} variant="outlined" size="small" href={pdfBlobUrl} download={`Ceysson & Bénétière - Artworks Checklists.pdf`}>
              Download PDF
        </Button>
          <FormControl component="fieldset">
 
            <FormLabel component="legend">Entries Per Page</FormLabel>
            <RadioGroup
              aria-label="entries-per-page"
              name="entriesPerPage"
              value={entriesPerPage}
              onChange={handleRadioChange}
              row
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="1 artworks / Page"
              />
              <FormControlLabel
                value="4"
                control={<Radio />}
                label="4 artworks / Page"
              />
                            <FormControlLabel
                value="8"
                control={<Radio />}
                label="8 artworks / Page (suitable for fairs label)"
              />
              <FormControlLabel
                value="10"
                control={<Radio />}
                label="10 artworks / Page (2 column)"
              />

              <FormControlLabel
                value="5"
                control={<Radio />}
                label="5 artworks / Page (1 column)"
              />
            </RadioGroup>
            <div>
              <FormLabel component="legend">Language</FormLabel>
              <RadioGroup
                aria-label="language"
                name="language"
                value={radioCustomValues["language"]}
                onChange={handleCustomRadioChange}
                row
              >
                <FormControlLabel value="EN" control={<Radio />} label="EN" />
                <FormControlLabel value="FR" control={<Radio />} label="FR" />
                <FormControlLabel value="JA" control={<Radio />} label="JA" />
              </RadioGroup>


              {checkboxCustomValues.showImage && <><FormLabel component="legend">Image Quality</FormLabel>
              <RadioGroup
               name="imageQuality"
                value={radioCustomValues['imageQuality']}
                onChange={handleCustomRadioChange}
                row
              >
              <FormControlLabel
                  value="2000-1200"
                  control={<Radio />}
                  label="Very High Quality"
                />
                <FormControlLabel
                  value="900-600"
                  control={<Radio />}
                  label="High Quality"
                />
                <FormControlLabel
                  value="450-300"
                  control={<Radio />}
                  label="Medium Quality"
                />
                <FormControlLabel
                  value="132-80"
                  control={<Radio />}
                  label="Low Quality"
                />
              </RadioGroup></>}

              <FormLabel component="legend">Options</FormLabel>


              <FormControlLabel
                key="showImage"
                control={
                  <Checkbox
                    checked={checkboxCustomValues["showImage"] || false}
                    onChange={handleCustomOptionChange}
                    name="showImage"
                  />
                }
                
                label="Show Image"
              />

              <FormControlLabel
                key="showArtistName"
                control={
                  <Checkbox
                    checked={checkboxCustomValues["showArtistName"] || false}
                    onChange={handleCustomOptionChange}
                    name="showArtistName"
                  />
                }
                label="Show Artist's Name"
              />


<FormControlLabel
                key="showTechnique"
                control={
                  <Checkbox
                    checked={checkboxCustomValues["showTechnique"] || false}
                    onChange={handleCustomOptionChange}
                    name="showTechnique"
                  />
                }
                label="Show Technique"
              />


<FormControlLabel
                key="showArtistDate"
                control={
                  <Checkbox
                    checked={checkboxCustomValues["showArtistDate"] || false}
                    onChange={handleCustomOptionChange}
                    name="showArtistDate"
                  />
                }
                label="Show Artist's Date"
              />

              <FormControlLabel
                key="showPrice"
                control={
                  <Checkbox
                    checked={checkboxCustomValues["showPrice"] || false}
                    onChange={handleCustomOptionChange}
                    name="showPrice"
                  />
                }
                label="Show Price"
              />


            <FormControlLabel
                key="showAvailability"
                control={
                  <Checkbox
                    checked={checkboxCustomValues["showAvailability"] || false}
                    onChange={handleCustomOptionChange}
                    name="showAvailability"
                  />
                }
                label="Show Availability"
              />


{entriesPerPage!=1 && (
<FormControlLabel
                key="showCBlogoEverywhere"
                control={
                  <Checkbox
                    checked={checkboxCustomValues["showCBlogoEverywhere"] || false}
                    onChange={handleCustomOptionChange}
                    name="showCBlogoEverywhere"
                  />
                }
                label="Show CB logo on Every labels"
              />)}

{checkboxCustomValues.showPrice && (
<FormControlLabel
                key="dontShowPriceIfNotAvailable"
                control={
                  <Checkbox
                    checked={checkboxCustomValues["dontShowPriceIfNotAvailable"] || false}
                    onChange={handleCustomOptionChange}
                    name="dontShowPriceIfNotAvailable"
                  />
                }
                label="Don't Show Price If Not Available"
              />)}

              <FormControlLabel
                key="forceShowPrice"
                control={
                  <Checkbox
                    checked={checkboxCustomValues["forceShowPrice"] || false}
                    onChange={handleCustomOptionChange}
                    name="forceShowPrice"
                  />
                }
                label="Force Show Price"
              />

              <FormControlLabel
                key="showChangeRate"
                control={
                  <Checkbox
                    checked={checkboxCustomValues["showChangeRate"] || false}
                    onChange={handleCustomOptionChange}
                    name="showChangeRate"
                  />
                }
                label="Show Change Rate"
              />


<FormControlLabel
                key="showProductionStatus"
                control={
                  <Checkbox
                    checked={checkboxCustomValues["showProductionStatus"] || false}
                    onChange={handleCustomOptionChange}
                    name="showProductionStatus"
                  />
                }
                label="Show Production Status"
              />

              <FormControlLabel
                key="showEditionStatus"
                control={
                  <Checkbox
                    checked={checkboxCustomValues["showEditionStatus"] || false}
                    onChange={handleCustomOptionChange}
                    name="showEditionStatus"
                  />
                }
                label="Show Edition Status"
              />

              {checkboxCustomValues["showEditionStatus"] && (
                <>
                  <FormLabel component="legend">Multiple info display</FormLabel>
                  <RadioGroup
                    aria-label="multipleShow"
                    name="multipleShow"
                    value={radioCustomValues["multipleShow"]}
                    onChange={handleCustomRadioChange}
                    row
                  >
                    <FormControlLabel
                      value="multiple"
                      control={<Radio />}
                      label="Show only when multiple"
                    />
                    <FormControlLabel
                      value="unique"
                      control={<Radio />}
                      label="Show for unique and multiple"
                    />
                  </RadioGroup>
                </>
              )}

              <FormLabel component="legend">Order</FormLabel>

              {sortOrders.map((sortOrder, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <FormControl style={{ marginRight: "10px", minWidth: 120 }}>
                    <InputLabel>Field to Sort By</InputLabel>
                    <Select
                      value={sortOrder.field}
                      onChange={(e) =>
                        handleChangeSortField(index, e.target.value)
                      }
                    >
                      <MenuItem key="artist_last_name" value="artist_last_name">
                        Artist
                      </MenuItem>
                      {fromId && fromTable && (<MenuItem key="fk_position" value="fk_position">
                      Position
                      </MenuItem>)}
                      {sortableFields.map((field) => (
                        <MenuItem key={field.key} value={field.key}>
                          {field.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl style={{ minWidth: 120 }}>
                    <InputLabel>Order</InputLabel>
                    <Select
                      value={sortOrder.order}
                      onChange={(e) =>
                        handleChangeSortOrder(index, e.target.value)
                      }
                    >
                      {sortOrdersOptions.map((order) => (
                        <MenuItem key={order} value={order}>
                          {order}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDeleteSortOrder(index)}
                    style={{ marginLeft: "10px" }}
                  >
                    Delete
                  </Button>
                </div>
              ))}
              <Button
                onClick={handleAddSortOrder}
                variant="outlined"
                color="primary"
              >
                Add Additional Order
              </Button>

              <FormLabel component="legend">Layout</FormLabel>

              <FormControlLabel
                key="showFrontPage"
                control={
                  <Checkbox
                    checked={checkboxCustomValues["showFrontPage"] || false}
                    onChange={handleCustomOptionChange}
                    name="showFrontPage"
                  />
                }
                label="Show Front Page"
              />

              {checkboxCustomValues["showFrontPage"] && (
                <>
                  <TextField
                    label="Title"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="title"
                    checked={checkboxCustomValues["title"]}
                    onBlur={handleFieldChange}
                  />

                  <TextField
                    label="Subtitle"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="subtitle"
                    checked={checkboxCustomValues["subtitle"]}
                    onBlur={handleFieldChange}
                  />

                  <TextField
                    label="Info Line"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    name="infoline"
                    checked={checkboxCustomValues["infoline"]}
                    onBlur={handleFieldChange}
                  />

                  <FormLabel component="legend">Choose Front Cover</FormLabel>
                  <Select
                    labelId="artwork-select-label"
                    id="artworkCover"
                    name="artworkCover"
                    value={checkboxCustomValues["artworkCover"]} // This state should hold the currently selected artwork id
                    onChange={handleFieldChange} // This function should handle the selection change
                    fullWidth
                  >
                    <MenuItem key="none" value="">
                      None
                    </MenuItem>
                    {data.map((item) => (
                      <MenuItem
                        key={item.id}
                        value={`${item.id}/main_image/${item.main_image}`}
                      >
                        {item.name} - {item.id}
                      </MenuItem>
                    ))}
                  </Select>

                  {checkboxCustomValues["artworkCover"] && (
                    <Slider
                      aria-label="Scale"
                      defaultValue={1}
                      name="zoom"
                      onChange={handleFieldChange}
                      checked={checkboxCustomValues["zoom"]}
                      valueLabelDisplay="auto"
                      step={0.1}
                      marks
                      min={0.1}
                      max={3}
                    />
                  )}

                  <FormLabel component="legend">
                    Front Page Card Style
                  </FormLabel>
                  <RadioGroup
                    aria-label="CardFrontBackGroundWhite"
                    name="CardFrontBackGroundWhite"
                    value={radioCustomValues["CardFrontBackGroundWhite"]}
                    onChange={handleCustomRadioChange}
                    row
                  >
                    <FormControlLabel
                      value="white"
                      control={<Radio />}
                      label="White"
                    />
                    <FormControlLabel
                      value="transparent"
                      control={<Radio />}
                      label="Transparent"
                    />
                  </RadioGroup>

                  <RadioGroup
                    aria-label="alignment"
                    name="alignment"
                    value={radioCustomValues["alignment"]}
                    onChange={handleCustomRadioChange}
                    row
                  >
                    <FormControlLabel
                      value="left"
                      control={<Radio />}
                      label="Align Left"
                    />
                    <FormControlLabel
                      value="right"
                      control={<Radio />}
                      label="Align right"
                    />
                    <FormControlLabel
                      value="bottom"
                      control={<Radio />}
                      label="Align Bottom"
                    />
                  </RadioGroup>

                  <FormLabel component="legend">Logos on Front Page</FormLabel>
                  <RadioGroup
                    aria-label="logos"
                    name="logos"
                    value={radioCustomValues["logos"]}
                    onChange={handleCustomRadioChange}
                    row
                  >
                    <FormControlLabel
                      key="noLogo"
                      value="noLogo"
                      control={<Radio />}
                      label="No Logo"
                    />
                    <FormControlLabel
                      key="logoNoCity"
                      value="NoCity"
                      control={<Radio />}
                      label="No City"
                    />
                    {logos?.map((logo) => (
                      <FormControlLabel
                        key={logo.id}
                        value={logo.display_name}
                        control={<Radio />}
                        label={logo.name}
                      />
                    ))}
                  </RadioGroup>
                </>
              )}

              <FormControlLabel
                key="showBackCover"
                control={
                  <Checkbox
                    checked={checkboxCustomValues["showBackCover"] || false}
                    onChange={handleCustomOptionChange}
                    name="showBackCover"
                  />
                }
                label="Show Back Cover"
              />
              {checkboxCustomValues["showBackCover"] && (
                <>
                  <FormLabel component="legend">
                    Choose Back Cover Background Color
                  </FormLabel>
                  <SwatchesPicker
                    color={checkboxCustomValues.colorBackCover}
                    onChange={handleColorChange}
                  />

                  <FormControlLabel
                    key="TextInWhite"
                    control={
                      <Checkbox
                        checked={checkboxCustomValues["TextInWhite"] || false}
                        onChange={handleCustomOptionChange}
                        name="TextInWhite"
                      />
                    }
                    label="Text In White"
                  />
                </>
              )}
            </div>
          </FormControl>
          <br />

          {/* PDF Download Link 
          
          <PDFDownloadLink
            document={documentMemo}
            fileName="Ceysson & Bénétière - Artworks Checklist.pdf"
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
          >
            {({ blob, url, loading, error }) =>
              loading ? "Loading document..." : "Download now!"
            }
          </PDFDownloadLink>*/}

          {/* BlobProvider for live preview */}
          <BlobProvider
            checkedFields={checkboxValues}
            checkedCustomFields={checkboxCustomValues}
            radioCustomValues={radioCustomValues}
            document={documentMemo}
            key={entriesPerPage}
          >
          {({ url, loading }) => (
    <>
      <br />
      <Button
        style={{
          marginBottom: '100px',
          marginRight: '20px',
          float: 'right',
        }}
        variant="outlined"
        size="small"
        href={loading ? undefined : url} // Prevent click when loading
        download={loading ? undefined : `Ceysson & Bénétière - Artworks Checklist.pdf`} // Prevent download when loading
        disabled={loading} // Disable button during loading
      >
        {loading ? 'Preparing PDF...' : 'Download PDF'}
      </Button>
      {(() => {
        if (url && url !== pdfBlobUrl) setPdfBlobUrl(url);
        return null;
      })()}
    </>
  )}
          </BlobProvider>
        </form>
      </div>

      {/* PDF Preview Section */}
      <div style={{ width: "50%", paddingLeft: "20px" }}>
        {pdfBlobUrl && (
          <iframe
            src={pdfBlobUrl}
            style={{ width: "100%", height: "100%", border: "none" }}
            title="PDF Preview"
          />
        )}
      </div>
    </div>
  );
}

export default MakeSpecificArtworksPdfFormModal;
