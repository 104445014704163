import React, { useState, useEffect } from "react";
import {
  InputAdornment,
  IconButton,
  FormControl,
  TextField,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import { useCache } from "../../contexts/CacheContext"; 
import { unslugify } from "../../utils/utils";


import { useQuery, useQueryClient } from "@tanstack/react-query";
import { loadItems } from "../../api"; // Adjust the import path as needed


const fetchData = async (url) => {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  };

const AutocompleteWithQuery = ({
  title,
  selectFile,
  selectFileKey = "id",
  conditionnalFileLoad,
  formData=null,
  value,
  selectFreesolo,
  disabled = false,
  onValueChange,
  sendBackJson=false,
  setNone=false,
  required=false
}) => {



  const { noCache } = useCache(); // Get the cache state
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState(value || '');






  const [offset, setOffset] = useState(0);
  const [fetchCount, setFetchCount] = useState(0);
  const queryClient = useQueryClient();
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [filteredCount, setFilteredCount] = useState(options.length);
  const [shouldRefetch, setShouldRefetch] = useState(false);

const fetchAllData = async () => {
  try {
    const cacheBuster = noCache ? `?${Date.now()}` : '';
    let url = `${process.env.REACT_APP_GLOBAL_MAPPING_URL}dynamic_json/${selectFile}.json${cacheBuster}`;
    if (shouldRefetch) {
      url += `?${new Date().getTime()}`;
    }
    const [optionsFetch] = await Promise.all([fetchData(url)]);

    if (setNone) {
      setOptions([{ id: 'SetNull', name: 'None' }, ...optionsFetch]);
    } else {
      setOptions(optionsFetch);
    }
    setShouldRefetch(false); // Reset the state after fetching

  } catch (error) {
    console.error("Fetching error:", error);
  }
};
useEffect(() => {
  fetchAllData();
}, [shouldRefetch]);

const handleButtonClick = () => {
  setShouldRefetch(true);
};




  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: [selectFile, offset],
    queryFn: () => loadItems(selectFile, { search: inputValue, limit: 1600, offset: 0}, 'id,name'),
    initialData: options,
    enabled: filteredCount < 4 && inputValue.length > 4,
    staleTime: 4 * 1000
  });



  useEffect(() => {
    if (data && data.length > 0) {
      const uniqueDataMap = new Map();
      
      // Add existing options to the map
      options.forEach(item => uniqueDataMap.set(item.id, item));
  
      // Add new data to the map, duplicates will be overridden
      data.forEach(item => uniqueDataMap.set(item.id, item));
  
      // Convert the map back to an array
      const uniqueData = Array.from(uniqueDataMap.values());
      
      setOptions(uniqueData);
      setFetchCount(fetchCount + 1);
  
      console.log('Unique data updated', uniqueData);
    }
  }, [data]);




  // Update the internal state when the external value changes
  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const handleAutocompleteChange = (event, newValue) => {
    console.log('log from auto', newValue);
    if(sendBackJson){
      onValueChange(newValue ? newValue : '');
    }else{
    onValueChange(newValue ? newValue[selectFileKey] : '');
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (selectFreesolo) {
      onValueChange(newInputValue);
    }
  };


  useEffect(() => {
    const selectedOption = options.find(option => option.id === formData[selectFileKey]);
    if (selectedOption) {
      setInputValue(selectedOption.name + ' ' + selectedOption.id);
    }
  }, [options]);



  
  const filterOptions = (options, { inputValue }) => {
    const filtered = options.filter(
        option => option?.name?.toLowerCase().includes(inputValue.toLowerCase())

    );
    setFilteredCount(filtered.length);
    return filtered;

  };

  const autocompleteWithQueryValue = options.find(option => option.id === value);
          


  return (
    <FormControl fullWidth margin="normal">
      <MuiAutocomplete
        value={autocompleteWithQueryValue || null}
        sx={{ width: "100%"}}
        disabled={disabled}
        freeSolo={selectFreesolo}
        filterOptions={filterOptions}
        options={options}
        getOptionLabel={(option) => option.n || option.name || ""}
        groupBy={(option) => option.categorie}
        onChange={handleAutocompleteChange}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        renderOption={(props, option) => (
            <li {...props} key={option.id}> {/* Use the unique 'id' as key */}
              {option.n || option.name} - {option.id}
            </li>
          )}
         renderInput={(params) => (
  <TextField
    {...params}
    label={unslugify(title)}
    required={required}
    value={inputValue}
    InputProps={{
      ...params.InputProps,
      endAdornment: (
        <>
          {params.InputProps.endAdornment}
          <InputAdornment position="end">
            <IconButton onClick={handleButtonClick}>
              <RefreshIcon />
            </IconButton>
          </InputAdornment>
        </>
      ),
    }}
  />
)}

      />
{shouldRefetch && "Data are being refreshed"}

    </FormControl>
  );
};

export default React.memo(AutocompleteWithQuery);
