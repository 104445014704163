import React, { useState, useEffect } from "react";
import useModelLoader from "../../../hooks/useModelLoader";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import useTranslations from "../../../hooks/useTranslations";
import fonts from "../../../utils/fonts";
import { Header, Footer } from "./HeaderAndFooter";
import { formatDateByLanguage } from "../../../utils/utils";

import { useSelectOptions } from "../../../hooks/useSelectOptions";
import { getDisplayValueWithFullModelData, addDimensionsDepthX, priceFormatting, addSeparatorIfNotNull } from "../../../utils/utils";
import { useCache } from "../../../contexts/CacheContext";


const styles = StyleSheet.create({
  translatableText: (language, font="Museo100") => ({
    fontFamily: language === "ja" ? "NotoRegular" : font,
  }),
  artistName: {
    paddingTop: 2,
    fontFamily: "MuseoSlab500",
    letterSpacing: 1,
    fontSize: 9,
    textTransform: "uppercase",
  },
  artworkTitle: {
    paddingTop: 3,
    fontFamily: "MuseoSans300i",
    fontSize: 7,
  },
  artworkDate: {
    paddingTop: 3,
    fontFamily: "Museo300",
    fontSize: 7,
  },
  page: (language) => ({
    fontSize: 9,
    fontFamily: language === "ja" ? "NotoRegular" : "Museo100",
    flexDirection: "column",
    backgroundColor: "white",
    height: "100%", // Ensure the page takes full height
    width: "100%",
    justifyContent: "flex-start",
  }),
  container:{
    paddingLeft: 40,
    paddingRight: 40,
  },
  image: {
    padding: 2,
    height: "150px" ,
    width: "150px" ,
    objectFit: "contain",
  },
  headerAddress:{
    width: "100%",
    fontFamily: "Museo300",
    fontSize:  8,
    borderTop: "0.5px solid black",
    textAlign: "center",
    paddingTop: 3,
    marginTop: 1,
    marginBottom: 3,
  },
  attentionTo:{
    width: "100%",
    textAlign: "left",
    marginTop: 70,
    marginBottom: 3,
  },
  artpartnersName:{
    width: "100%",
    fontFamily: "Museo500",
    textAlign: "left",
    fontSize:  11,
  },
  artistsName:{
    width: "100%",
    fontFamily: "Museo500",
    textAlign: "left",
    fontSize:  14,
  },
  artworksTitle:{
    fontFamily: "Museo300i",
    textAlign: "left",
    fontSize:  11,
  },
  artworksDate:{
    fontFamily: "Museo300",
    textAlign: "left",
    fontSize:  11,
  },
  infoDisplay:{
    fontFamily: "Museo100",
    textAlign: "left",
    marginTop: 1
  }


});





const InvoiceProformaPDF = ({
  section,
  customValues,
  invoiceId,
  data,
  handleFileName,
  ...props
}) => {


  const allTranslation = useTranslations(['pdf_invoices']); // Specify your file names here

  const [entitiesData, setEntitiesData] = useState(null);

  const { noCache = false } = useCache(); // Fallback to false if noCache is undefined

  const cacheBuster = noCache ? `?${Date.now()}` : "";


  useEffect(() => {
    const fetchData = async () => {
      try {
        const entitiesUrl = `${process.env.REACT_APP_GLOBAL_MAPPING_URL}single_file_json/entities_${data.entities_id}.json${cacheBuster}`;
        const countriesUrl = `${process.env.REACT_APP_GLOBAL_MAPPING_URL}countries_all.json`;
        
        const [entitiesResponse, countriesResponse] = await Promise.all([
          fetch(entitiesUrl),
          fetch(countriesUrl),
        ]);
  
        const [entities, countries] = await Promise.all([
          entitiesResponse.json(),
          countriesResponse.json(),
        ]);
  
        const entity = entities[0];
        const countryData = countries.find(country => country.id === entity.country);

        // Directly update entitiesData with country name if countryData is found
        if (countryData) {
          setEntitiesData({
            ...entity,
            country: countryData.n, // Assuming 'n' is the property with the country name
          });
        } else {
          // Optionally handle case where country data is not found
          setEntitiesData(entity); // Set without modifying country
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error gracefully
      }
    };
  
    fetchData();
  }, [data]);

  
  useEffect(() => {
    fonts();
  }, []);






  const formattedDate = formatDateByLanguage(data?.invoice_date, customValues.language); // For French

  const { modelData: modelDataArtworks, filteredFields } = useModelLoader("artworks");
  const selectMappingsArtworks = useSelectOptions(modelDataArtworks?.fields);



  const { modelData } = useModelLoader("addresses");
  const selectMappings = useSelectOptions(modelData?.fields);






const priceToFormat = (price, currency=data.currency, country=customValues.priceFormatting, showCents=customValues.showCents) => {
  const formattedPrice = priceFormatting(price, currency, country, showCents);
  return formattedPrice;
 }


 const LetterInvoice = section === "proforma" ? "PA-" :  "A-";


 useEffect(() => {
  handleFileName(`${data.artpartners_name || "client"}-${data.invoice_date}-${LetterInvoice}${data?.artworks_id}-${invoiceId}`)
}, [data]);


if (!data || !entitiesData || !modelData || !allTranslation) {
  return    (<Document>
  <Page
  wrap
    key="mainPage"
    style={styles.page}
    size={customValues.size}
  > Loading</Page></Document>)
}


const lang = customValues.language;

// Replace _fr with _[lang] dynamically
const text = entitiesData[`text_invoice_${lang}`];
const legalText = text?.normalize('NFC').replace(/<\/p><p>/g, "\n").replace(/<\/p>/g, " ").replace(/<p>/g, " ");

const text_vat = entitiesData[`text_vat_${lang}`];
const vatText = text_vat?.normalize('NFC').replace(/<\/p><p>/g, "\n").replace(/<\/p>/g, " ").replace(/<p>/g, " ");

const text_vat_export = entitiesData[`text_vat_export_${lang}`];
const vatTextExport = text_vat_export?.normalize('NFC').replace(/<\/p><p>/g, "\n").replace(/<\/p>/g, " ").replace(/<p>/g, " ");


const discountAmount = data?.public_price_at_time_of_sale-data?.sales_price_with_taxes;
const discountPercent = Math.ceil((discountAmount / data?.public_price_at_time_of_sale * 100) * 10) / 10;


const reference = LetterInvoice+data?.artworks_id+"-"+invoiceId;





  return (
    <Document>
        <Page
        wrap
          key="mainPage"
          style={styles.page(lang)}
          size={customValues.size}
        >

          <View style={styles.container}>

              <View
                key="Brand"
                style={styles.section}
              >
    <Header />
                
                <Text style={styles.headerAddress}>
                {entitiesData?.address} {entitiesData?.city} {entitiesData?.zip_code}  {entitiesData?.country}
                </Text>
             </View>


             <View key="Attention_to" style={styles.attentionTo}>
            <Text style={styles.placeAndDate}>
              {entitiesData?.city}, {formattedDate}
            </Text>
            <Text style={{    width: "100%",
    textAlign: "left",
    marginTop: 10,
    marginBottom: 3, ...styles.translatableText(lang)}}>
            {allTranslation.attention_to?.[lang]}
            </Text>
            <Text style={{...styles.artpartnersName, marginTop: 1}}>
            {data.artpartners_name || 'N/A'} 
            </Text>
            <Text style={styles.infoDisplay}>
              {data.address|| 'N/A'}
              </Text>
              <Text style={styles.infoDisplay}>
              {data.zip_code|| 'N/A'} {data.city|| 'N/A'}{"\n"}
              </Text>
              <Text style={styles.infoDisplay}>
              {getDisplayValueWithFullModelData(modelData, "country", data.country, selectMappings, false)} 
              </Text>


            <Text style={{...styles.translatableText(lang, "Museo500"), fontSize:  11,marginTop: 20, marginBottom: 20}}>
            {allTranslation.invoice?.[lang]} n° {reference}
            </Text>
          </View>
<View key="ImageAndData" style={{ flexDirection: "row"}}>
<View key="Image" style={{ flexGrow: 0}}>
<Image style={styles.image}
src={`${process.env.REACT_APP_S3_BASE_IMG}artworks/${data.artworks_id}/main_image/${data.artworks_main_image}-450-300.jpg?`}
                  />
</View>

<View key="Data" style={{ flexGrow: 1, marginLeft: 20, 
  marginLeft: 20,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'}}>
<Text style={{...styles.artistsName}}>
{getDisplayValueWithFullModelData(modelDataArtworks, "artists_id", data.artists_id, selectMappingsArtworks, false)} {"\n"}
</Text>

<View style={{flexDirection: "row", width: "100%" }}>
<Text style={styles.artworksTitle}>
              {data.artworks_name}
            </Text>
            <Text style={styles.artworksDate}>, {data.artworks_creation_date_start}{addSeparatorIfNotNull(data.artworks_creation_date_end)}
            </Text>
            </View>
            <Text style={styles.infoDisplay}>
            {data[`added_medium_${lang}`]}
              </Text>
              <Text style={styles.infoDisplay}>
              {data.height_cm} x {data.width_cm}{addDimensionsDepthX(data.depth_cm)} cm{"\n"}
              </Text>
              <Text style={styles.infoDisplay}>
              {data.height_in} x {data.width_in}{addDimensionsDepthX(data.depth_in)} in{"\n"}
              </Text>
</View>

</View>
<View key="PriceAndLine" style={{ marginTop: 50, width: "100%", flexDirection: "column", alignItems:"right"}}>

{customValues.showPriceBreakDown && (
  <View>
<Text style={{ width: "100%", textAlign: "right"}}>{allTranslation['public_price']?.[customValues.language]}: {priceToFormat(data.public_price_at_time_of_sale)}</Text>
<Text style={{ width: "100%", textAlign: "right"}}>{allTranslation['special_discount']?.[customValues.language]} ( - {discountPercent}%): {priceToFormat(discountAmount)}</Text>
</View>
  )}


{customValues.showVAT && (data.tax_rate_type === 1 || data.tax_rate_type === 5) && (
  <View>
<Text style={{ width: "100%", textAlign: "right"}}>{allTranslation.price_excluding_VAT?.[customValues.language]}: {priceToFormat(data.sales_price_without_taxes)}</Text>
<Text style={{ width: "100%", textAlign: "right"}}>{allTranslation.VAT?.[customValues.language]}: ( {data.tax_rate}%): {priceToFormat(data.tax_amount)}</Text>
</View>
  )}

{data.tax_rate_type === 4 ? (
  <Text style={{ width: "100%", textAlign: "right"}}>{allTranslation.price_for_export?.[lang]}: {priceToFormat(data.sales_price_with_taxes)}</Text>
):
(<Text style={{ width: "100%",textAlign: "right"}}>{allTranslation.final_price_including_VAT?.[lang]}: {priceToFormat(data.sales_price_with_taxes)}</Text>
)}

{customValues.showVATLine && <Text style={{textAlign: "right", marginTop: 5, paddingTop: 5, borderTop: "0.5px solid black", width: "100%"}}>{vatText}</Text>}
{customValues.showVATExportLine && <Text style={{textAlign: "right", marginTop: 5, paddingTop: 5, borderTop: "0.5px solid black", width: "100%"}}>{vatTextExport}</Text>}


{!customValues.showVATLine && !customValues.showVATExportLine && <View style={{textAlign: "right", marginTop: 5, paddingTop: 5, borderTop: "0.5px solid black", width: "100%"}}></View>}



<Text style={{textAlign: "right", marginTop: 10,  paddingTop: 5}}>{allTranslation.greeting?.[lang]}</Text>

</View>
</View>

<View key="BankInfo" style={{ position: "absolute", bottom: 120, width: "100%",padding: 40}}>
<Text style={{ ...styles.translatableText(lang, "Museo500"), width: "100%",  paddingTop: 2,
    letterSpacing: 1,
    fontSize: 11}}>{allTranslation.bank_info?.[lang]}</Text>
    <Text style={{ width: "100%"}}>{allTranslation.account_owner?.[lang]}: {entitiesData?.name}</Text>
    <Text style={{ width: "100%"}}>Iban : {data.iban}</Text>
    <Text style={{ width: "100%"}}>Swift / Bic : {data.swift}</Text>

    <View style={{ width: "100%", flexDirection: "row",   display: 'flex',
  justifyContent: 'space-between'}}>
      {data.domiciliation && (<Text >{allTranslation.domiciliation?.[lang]}: {data.domiciliation}</Text>)}
      {data.bank_code && (<Text >{allTranslation.bank_code?.[lang]}: {data.bank_code}</Text>)}
      {data.sort_code && (<Text >{allTranslation.sort_code?.[lang]}: {data.sort_code}</Text>)}
      {data.accounts_key && (<Text>{allTranslation.account_key?.[lang]}: {data.accounts_key}</Text>)}
      {data.account_number && (<Text >{allTranslation.account_number?.[lang]}: {data.account_number}</Text>)}
</View>      
<Text style={{ width: "100%"}}>{allTranslation.wire_message?.[lang]} : {reference}</Text>
</View>


          <View key="Legal" style={{ position: "absolute", bottom: 50, width: "100%",padding: 40}}>
<Text style={{ width: "100%", textAlign: "center", fontSize: 6}}>
{legalText} 
</Text>
</View>
          <Footer />
        </Page>

    </Document>
  );
};

export default InvoiceProformaPDF;
